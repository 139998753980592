import { Direction, BidiModule } from '@angular/cdk/bidi';
import { RouterOutlet } from '@angular/router';
import { Component } from '@angular/core';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { NavbarComponent } from 'app/web/navbar/navbar.component';


@Component({
  selector: 'app-web-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    NavbarComponent,
  ],
  templateUrl: './web-layout.component.html',
  styleUrl: './web-layout.component.scss'
})
export class WebLayoutComponent extends UnsubscribeOnDestroyAdapter {
  direction!: Direction;

}