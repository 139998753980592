<div id="web-navbar">
    <nav class="navbar">
        <a routerLink="/" class="brand">
            <div class="brand_logo"></div>
            <span class="brand_name">Shunkan id&#333;</span>
        </a>
        <div class="currentMenuName"><span>{{currentMenuItem}}</span></div>
        <div class="nav_menu">
            <ul class="nav_list">
                <li class="nav_item" (click)="menuItemClicked('Home')">
                    <a routerLink="/" class="nav_link">
                        <span class="nav_icon iconify-inline" data-icon="ant-design:home-outlined"></span>
                        <span class="nav_name">Home</span>
                    </a>
                </li>
                <li class="nav_item submenu" (click)="menuItemClicked('Pricing')">
                    <a routerLink="/Pricing" class="nav_link">
                        <span class="nav_icon iconify-inline" data-icon="icon-park-outline:paper-money"></span>
                        <span class="nav_name">Pricing</span>
                        <ul class="dropdown" (click)="dropDownClicked()">
                            <li class="dropdown_item" (click)="dropDownClicked()">Local 1</li>
                            <li class="dropdown_item" (click)="dropDownClicked()">International 1</li>
                            <li class="dropdown_item" (click)="dropDownClicked()">Consolidation 1</li>
                            <li class="dropdown_item" (click)="dropDownClicked()">Local 2</li>
                            <li class="dropdown_item" (click)="dropDownClicked()">International 2</li>
                            <li class="dropdown_item" (click)="dropDownClicked()">Consolidation 2</li>
                            <li class="dropdown_item" (click)="dropDownClicked()">Local 3</li>
                            <li class="dropdown_item" (click)="dropDownClicked()">International 3</li>
                            <li class="dropdown_item" (click)="dropDownClicked()">Consolidation 3</li>
                        </ul>
                    </a>
                </li>
                <li class="nav_item" (click)="menuItemClicked('Contact')">
                    <a routerLink="/Contact" class="nav_link">
                        <span class="nav_icon iconify-inline" data-icon="bi:chat-dots"></span>
                        <span class="nav_name">Contact</span>
                    </a>
                </li>
                <li class="nav_item" (click)="menuItemClicked('Account')">
                    <a routerLink="/authentication" class="nav_link">
                        <span class="nav_icon iconify-inline" data-icon="material-symbols:account-circle-outline"></span>
                        <span class="nav_name">Account</span>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</div>