import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> 
  {

    const cloneReq = req.clone({
      setHeaders: {
        'step1care-api-key': environment.apiKey,
      }
    });

    return next.handle(cloneReq);
  }
}