import { Component, HostListener } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {

  protected currentMenuItem: string = "Home";

  constructor() { }

  ngOnInit(): void 
  {
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    
    let scrollY = window.scrollY;
    const navbar = document.getElementById('web-navbar') as Element;
  
    if (navbar) {
      const firstChild = navbar.firstChild as Element;
  
      if (firstChild) {
        if (scrollY >= 80) {
          firstChild.classList.add('scrolled_navbar');
        } else {
          firstChild.classList.remove('scrolled_navbar');
        }
      }
    }
  }

  protected menuItemClicked(name: string) 
  {
    console.log(name);
  }

  protected dropDownClicked()
  {

  }
}
